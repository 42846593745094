<template>
  <Tab :list="tabList" :current="0" />
  <div class="flex-1 fff">
    <!-- <van-row class="row">
      <van-col
        v-for="(item, index) in detail.list"
        :key="index"
        :span="item.span"
        class="col"
      >
        <span class="label">{{ item.label }}：</span> {{ item.value }}
      </van-col>
    </van-row> -->
    <van-row class="row">
      <van-col
        :span="12"
        class="col"
      >
        <span class="label">市场名称：</span> {{ detail.info.deptName }}
      </van-col>
      <van-col
        :span="12"
        class="col"
      >
        <span class="label">市场负责人：</span> {{ detail.info.managerUserName }}
      </van-col>
      <van-col
        :span="12"
        class="col"
      >
        <span class="label">服务热线：</span> {{ detail.info.mobile }}
      </van-col>
      <van-col
        :span="12"
        class="col"
      >
        <span class="label">食安检测师：</span> {{ detail.info.superiorName }}
      </van-col>
    </van-row>
    <div class="content">
      <h2>市场简介</h2>
      <div v-html="detail.info.deptDesc"></div>
    </div>
  </div>
</template>

<script>
import { supervisionTabList } from '@/utils/tab'
import { reactive } from '@vue/reactivity'
import Tab from '@/components/tab/index.vue'
import { getDetail } from '@/api/supervision/index'

export default {
  components: {
    Tab,
  },
  setup() {
    const tabList = reactive(supervisionTabList)

    let detail = reactive({
      list: [],
      content: '',
      info: {}
    })
    getDetail(4).then((res) => {
      detail.info = res.result[0]
    })

    return {
      tabList,
      detail
    }
  },
}
</script>

<style lang="less" scoped>
.row {
  padding: 12px;
  .col {
    display: flex;
    align-items: center;
    color: #666;
    line-height: 24px;
  }
  .label {
    width: 90px;
    text-align: right;
    color: #333;
  }
}

.content {
  h2 {
    text-align: center;
    font-weight: normal;
    font-size: 18px;
  }
  > div {
    padding: 0 12px;
  }
}
</style>
